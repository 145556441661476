
import { Options, Vue } from 'vue-class-component';
import { EventDetailClient, AppUserClient } from '@/services/Services';
import * as OM from '@/Model';
import sendPushModal from '@/components/modals/sendPushModal.vue';
import userModal from '@/components/modals/userModal.vue';

@Options({})
export default class surveyList extends Vue {

    items: OM.EventDetail[] = [];

    created(){
        EventDetailClient.getAll()
        .then(x => {
            this.items = x;
            this.items.forEach(x => {
                if(new Date(x.endDate) <= new Date()){
                    (x as any).passato = true;
                }
            })
        })
    }

    pushModal(){
        AppUserClient.getAllUserIdentifiers()
        .then(x => {
            this.$opModal.show(sendPushModal, {
                users: x,
                route: "/notifications"
            })
        })
    }

    openUserModal(users: string[]){
        this.$opModal.show(userModal, {
            users: users
        })
    }

}
